import axios from "axios";
import {SERVER_URL, DEVELOPMENT_SERVER_URL} from "../config/server";
import router from "../router";
import {encodeHandleAES,generateRandomString,encodeHandleRsa,decodeHandleAES} from '../store/utils'

export function request(config) {
    // const randomString = generateRandomString(16);
    // const publicKey =`-----BEGIN PUBLIC KEY-----
    //                 MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC3PMuRhwPT1kry+9Mngu8KmjeK
    //                 tonie2WpegxxlCrn/wruznhLot7srY7JI29Nbirxhh2HOOnq21I/lpe39wLzY4vh
    //                 U3h6JebmeEuGHDQxwMfV6aT79NAkB0ioSl13v1XOfeOlpMFnEm/gSBA5RhtLGzbY
    //                 j4rLhS7qoYCf1J8QLwIDAQAB
    //                 -----END PUBLIC KEY-----
    //             `;
    const instance = axios.create({
        baseURL:
            process.env.NODE_ENV === "development"
                ? DEVELOPMENT_SERVER_URL
                : SERVER_URL,
        timeout: 60 * 1000,
    });
    instance.interceptors.request.use(
        (config) => {
            let loginUrl = "/buaa/auth/login";
            let getPdfInfoNoLogin = "/buaa/download/getPdfInfoNoLogin";
            // if (config.data && Object.keys(config.data).length > 0) {
            //     // 加密请求参数
            //     let data = config.data;
            //     config.data = {};
            //     config.data.params = encodeHandleAES(JSON.stringify(data), randomString);
            // }
            // config.headers.rk = encodeHandleRsa(randomString,publicKey);
            if (config.url == loginUrl || config.url == getPdfInfoNoLogin) {
                return config;
            }
            let token = localStorage.getItem("index-token");
            if (token) {
                config.headers.token = token;
            } else {
                // 清除本地的 token
                window.localStorage.clear();
                router.push({path: "/login"});
                let error = new Error("请登录！");
                return Promise.reject(error);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            // if(res.data.data){
            //     res.data.data = JSON.parse(decodeHandleAES(res.data.data, randomString));
            // }
            if (res.data.code == 400003 ||
                res.data.code == 399999) {
                // 清除本地的 token
                window.localStorage.clear();
                router.push({path: "/login"});
            }
            return res;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return instance(config);
}

// 合并多个请求
export function allRequest(arrRequest) {
    return axios.all(arrRequest);
}
